import React, {useState} from "react"
import { FaEnvelope } from 'react-icons/fa'
import { navigate } from 'gatsby'
import {useDispatch, useSelector} from "react-redux"

import "./union-member-single.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import {onChangeByName} from "../redux/registration/registrationAction"
import {checkMember} from "../redux/api/apiAction"
import {isDefined} from "../utils/data"

const UnionMemberSinglePage = () => {
    const dispatch  = useDispatch()
    const errorAPI  = useSelector(({apiReducer}) => apiReducer.login_failed)
    const [success, setSuccess] = useState(false)
    const [error, setError]     = useState(false)
    const [email, setEmail]     = useState("")
    const [validationEmail, setValidationEmail] = useState("")

    const onChange = e => {
        const {value} = e.target
        setEmail(value)
    }

    const checkValidation = () => {
        if(email === "")
            setValidationEmail("Enter your email")
        else
            setValidationEmail("")

        return email.length > 0
    }

    const onSubmit = e => {
        e.preventDefault()

        if(checkValidation()) {
            dispatch(checkMember(email))
                .then(res => {
                    if(res === true) {
                        setEmail("")
                        setSuccess(true)
                        setError(false)
                        dispatch(onChangeByName("unionMember", true))
                        navigate("non-member-single")
                    }
                    else {
                        setSuccess(false)
                        setError(true)
                    }
                })
        }
        else {
            setSuccess(false)
            setError(true)
        }
    }

    return (
        <Layout main={false}>
            <SEO title="Registration" />
            <Header/>

            <div className={"union-member-single"}>
                <h2 className={"title"}>To log in as a member of The Union, please enter your email.<br/>Please use the same email address that you are registered with in the membership platform.</h2>
                <form onSubmit={onSubmit}>
                    <div className="form-row">
                        <div className={"col-1"}>
                            <label><FaEnvelope/></label>
                        </div>
                        <div className={"col-11"}>
                            <input type="email" className="form-control" placeholder="Email" name={"email"} value={email} onChange={onChange} required/>
                            {validationEmail.length > 0 ?
                                <div className="invalid-feedback">
                                    {validationEmail}
                                </div> : ""}
                        </div>
                    </div>

                    <div className={"form-row"}>
                        {error ?
                            <div className="error invalid-feedback">
                                {isDefined(errorAPI) ? errorAPI.message : "Error"}
                            </div>
                            : ""}

                        {success ?
                            <div className="success valid-feedback">
                                Succeeded
                            </div>
                            : ""}
                    </div>

                    
                    <div className={"form-footer form-with-register"}>
                        {error ? <button onClick={() => {navigate("non-member-single")}} className={"btn btn-info btn-round btn-register"}>Non member registration</button> : <button onClick={() => {navigate("member-single")}} className={"btn btn-info btn-round btn-register"}>Back</button>}
                        <button type={"submit"} className={"btn btn-info btn-round"}>Search</button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default UnionMemberSinglePage
